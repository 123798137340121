td {
  padding: 0px 4px !important;
  line-height: 29px !important;
}

.contrato-column{
  width: 4%;
}

th{
  line-height: 12px !important;
}
tr,thead {
  line-height: 17px !important;
}

.table-row-light {
    background-color: #ffffff;
  }
  .table-row-dark {
    background-color: #f5f5f5;
  } 
  
  tbody tr:hover td {
     
      background-color: #dfdfdf !important;
    }
    
    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
      background: #f7f7f7;
      margin-top: 1px;
      margin-bottom: 1px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #bee3f8;
      border-radius: 20px;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #1677ff;
    }
    ::-webkit-scrollbar-button {
      width: 50px;
      height: 50px;
    }
    
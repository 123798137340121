
.logo {
  padding: 20px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
 
  background: #fff;
  padding: 0;
  margin: 0;
}



